<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="7" class="d-flex flex-column">
        <basicData
          v-if="!loading && employee !== null"
          v-model="employee"
          :readOnly="true"
          :positionOptions="positionOptions"
          :supervisorOptions="supervisorOptions"
          :hierarchyOptions="hierarchyOptions"
          :locationOptions="locationOptions"
          :saving="basicsSaving"
          @save="saveBasicData"
        ></basicData>
        <v-card v-else>
          <v-card-text>
            <v-skeleton-loader
              loading
              type="heading, list-item@8"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="4" lg="5" class="d-flex flex-column">
        <feedbacks
          v-if="employee !== null && feedbacks !== null"
          :feedbacks="activeFeedbacks"
          :recipient="employee"
          :readOnly="true"
          @feedbackCreated="createFeedback"
          @feedbackUpdated="reloadFeedbacks"
          @createPdp="createPdp"
          @creationComplete="markProcessCreated"
        ></feedbacks>
        <v-card v-else>
          <v-card-text>
            <v-skeleton-loader
              loading
              type="heading, list-item@8"
            ></v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="employee !== null && employee.hasPdpProcess">
      <v-col cols="12">
        <pdp-overview
          :readOnly="true"
          :userId="employeeId"
          :mode="'supervisor'"
        ></pdp-overview>
      </v-col>
    </v-row>

    <v-card
      class="segmented mt-6 mb-6"
      flat
      v-if="employee !== null && hasArchivedFeedbacks"
    >
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <h5>{{ $t('feedbacks.archive') }}</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <feedbacks-archive
          :feedbacks="archivedFeedbacks"
          :loading="false"
          :searchable="false"
          :isArchive="true"
          :employeeColumn="'supervisor'"
        ></feedbacks-archive>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-card flat class="segmented">
          <v-card-title>
            <h5>{{ $t('employeeDetails.employees') }}</h5>
          </v-card-title>
          <v-card-text>
            <employees-overview
              :employees="childEmployees"
              :detailsRoute="detailsRoute"
            ></employees-overview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" color="success" :timeout="timeout">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-check-circle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.dataSaved') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" color="error" :timeout="timeout">
      <v-layout align-center pr-4>
        <v-icon class="pr-3" dark large>mdi-exclamation-triangle</v-icon>
        <v-layout column>
          <div>{{ $t('toast.errorOcurred') }}</div>
        </v-layout>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { v4 as uuid } from 'uuid';
import { feedbackEnums } from '@/enums/feedbacks.js';

import basicData from '@/components/employeeDetails/basic-data';
import feedbacks from '@/components/employeeDetails/feedbacks';
import feedbacksArchive from '@/components/feedbacks/archive-table.vue';
import employeesOverview from '@/components/employeeDetails/employees-table.vue';
import pdpOverview from '@/components/feedbacks/pdp/pdp-overview.vue';

import userService from '@/services/UserService.js';

export default {
  components: {
    basicData,
    feedbacks,
    feedbacksArchive,
    employeesOverview,
    pdpOverview
  },

  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: true
    },

    detailsRoute: {
      type: String,
      required: false,
      default: 'EmployeeDetails'
    }
  },

  data: () => ({
    employee: null,
    childEmployees: [],
    feedbacks: null,
    snackbar: false,
    errorMessage: false,
    timeout: 3000,
    basicsSaving: false,
    feedbacksLoading: true,
    loading: true
  }),

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      positions: 'users/getPositions',
      supervisorOptions: 'users/getSupervisorOptions',
      hierarchies: 'users/getHierarchies',
      locations: 'users/getLocations',
      getChildEmployees: 'users/getChildEmployees',
      getOverviewUsers: 'users/getOverviewUsers'
    }),

    activeFeedbacks() {
      if (!this.feedbacks) {
        return [];
      }

      return this.feedbacks.filter((x) => !x.archived);
    },

    archivedFeedbacks() {
      if (!this.feedbacks) {
        return [];
      }

      return this.feedbacks.filter((x) => x.archived);
    },

    hasArchivedFeedbacks() {
      return this.archivedFeedbacks.length > 0;
    },

    hasMafegFeedbacks() {
      return this.feedbacks?.some(
        (x) => x.processType === feedbackEnums.processTypes.MAFEG
      );
    },

    positionOptions() {
      return this.positions;
    },

    locationOptions() {
      return this.locations;
    },

    hierarchyOptions() {
      return this.hierarchies;
    },

    employeeShorthand() {
      return this.$route.params.shorthand ?? this.currentUser?.shorthand ?? '';
    },

    employeeId() {
      if (this.employee !== null) {
        return this.employee.id;
      }

      return null;
    }
  },

  watch: {
    employeeShorthand() {
      if (this.employee !== null) {
        this.setUserData();
      }
    },

    getOverviewUsers() {
      this.childEmployees = this.getChildEmployees(this.employee.id);
    }
  },

  async mounted() {
    await this.fetchOptions();
    await this.setUserData();

    if (!this.readOnly) {
      await this.fetchAllUsers();
    } else {
      await this.fetchMyEmployees();
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getEmployee: 'users/fetchUserDetails',
      fetchAllUsers: 'users/fetchUsers',
      fetchMyEmployees: 'users/fetchMyEmployees',
      fetchOptions: 'users/fetchOptions',
      addFeedback: 'feedbacks/createFeedback',
      addPdp: 'feedbacks/createPdp',
      listFeedbacks: 'feedbacks/listFeedbacksAsRecipient',
      importArchive: 'feedbacks/importArchive'
    }),

    ...mapMutations({
      setUser: 'users/setUser'
    }),

    async createFeedback(feedbackData) {
      this.feedbacks.push(feedbackData);

      await this.addFeedback(feedbackData);
    },

    async createPdp() {
      await this.addPdp(this.employee.id);
    },

    markProcessCreated(processId) {
      const newFeedback = this.feedbacks.filter((x) => x.id === processId);
      if (newFeedback.length > 0) {
        const index = this.feedbacks.indexOf(newFeedback[0]);
        this.feedbacks[index].loading = false;
        //newFeedback[0].loading = false;
      }
    },

    async setArchiveData() {
      const archiveData = await this.importArchive(this.employee.shorthand);

      if (archiveData.isAvailable) {
        this.feedbacks.push({
          customArchive: true,
          archived: true,
          processType: feedbackEnums.processTypes.MAFEG,
          statusId: 170,
          startDate: archiveData.startDate,
          endDate: archiveData.endDate,
          donorFirstName: archiveData.donorFirstname,
          donorLastName: archiveData.donorLastname,
          talkDate: archiveData.talkDate,
          feedbacks: [
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.SELF_EVALUATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.selfEvaluationUrl,
              canRead: !this.employee.restrictedAccess
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.MANAGER_EVALUATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.supervisorEvaluationUrl,
              canRead: !this.employee.restrictedAccess
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.DOCUMENTATION,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.conclusionUrl,
              canRead: !this.employee.restrictedAccess
            },
            {
              id: uuid(),
              feedbackType: feedbackEnums.feedbackTypes.COMPARISON,
              submitted: true,
              approved: true,
              customArchive: true,
              customUrl: archiveData.comparisonUrl,
              canRead: !this.employee.restrictedAccess
            }
          ]
        });
      }
    },

    async setUserData() {
      this.employee = await this.getEmployee(this.employeeShorthand);
      if (this.employee === null) {
        this.employee = {};
        this.feedbacks = [];
        this.errorMessage = true;
      } else {
        this.childEmployees = this.getChildEmployees(this.employee.id);

        this.feedbacks = null;
        const feedbackData = await this.listFeedbacks(this.employee.id);
        this.feedbacks = feedbackData;

        this.setArchiveData();
      }

      this.feedbacksLoading = false;
    },

    async reloadFeedbacks() {
      this.feedbacks = await this.listFeedbacks(this.employee.id);
    },

    async saveBasicData() {
      this.basicsSaving = true;
      this.setUser(this.employee);

      await userService.saveUser(this.employee);

      this.basicsSaving = false;
      this.snackbar = true;
    }
  }
};
</script>
