<template>
  <user-details :readOnly="true"></user-details>
</template>

<script>
import userDetails from '@/components/employeeDetails/user-details.vue';
export default {
  components: {
    userDetails
  }
};
</script>
