<template>
  <v-card flat class="segmented flex d-flex flex-column">
    <v-card-title>
      <h5>{{ $t('employeeDetails.feedbacks.title') }}</h5>
    </v-card-title>

    <v-card-text class="flex">
      <v-list>
        <v-alert v-if="feedbacks.length === 0" border="bottom" text type="info">
          {{ $t('feedbacks.noFeedbacksInfo') }}
        </v-alert>

        <template v-else>
          <feedback-list-item
            v-for="(item, index) in feedbacks"
            :key="`feedback-list-item-${index}`"
            :item="item"
            :recipient="recipient"
            :isAdmin="isAdmin"
            @updated="feedbackUpdated"
          ></feedback-list-item>
        </template>

        <v-list-item v-if="!readOnly" class="pa-0">
          <v-list-item-content>
            <create-feedback-modal
              :recipient="recipient"
              @created="feedbackCreated"
            ></create-feedback-modal>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAdmin">
          <v-list-item-content>
            <v-switch
              v-model="recipient.hasPdpProcess"
              :label="$t('pdp.title')"
              :disabled="readOnly"
              @change="createPdp"
              class="mt-0"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import createFeedbackModal from '@/components/employeeDetails/create-feedback-modal';
import feedbackListItem from './feedback-list-item.vue';

import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    feedbacks: {
      type: Array,
      required: true
    },

    recipient: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    createCorrelationId: null,
    updateCorrelationId: null
  }),

  methods: {
    createPdp() {
      this.$emit('createPdp');
    },

    feedbackCreated(data) {
      this.createCorrelationId = data.correlationId;
      this.$hub.enterCreateProcessGroup(data.correlationId);
      this.$hub.$on('feedback-process-created', this.onProcessCreated);

      data.donorId = data.donor.value;
      data.donorName = data.donor.text;
      data.feedbacks = [
        {
          formId: data.formId
        }
      ];

      if (data.mentor !== null && data.mentor !== undefined) {
        data.donorId = data.mentor.value;
        data.mentorId = data.donor.value;
      }

      this.$emit('feedbackCreated', data);
    },

    feedbackUpdated() {
      this.updateCorrelationId = uuidv4();
      this.$hub.enterUpdateProcessGroup(this.updateCorrelationId);
      this.$hub.$on('feedback-process-updated', this.onProcessUpdated);
    },

    onProcessCreated(data) {
      this.$emit('creationComplete', data.processId);
    },

    onProcessUpdated() {
      this.$emit('feedbackUpdated');
    }
  },

  beforeDestroy() {
    if (this.createCorrelationId !== null) {
      this.$hub.leaveCreateProcessGroup(this.createCorrelationId);
      this.$hub.$off('feedback-process-created', this.onProcessCreated);
    }
    if (this.updateCorrelationId !== null) {
      this.$hub.leaveUpdateProcessGroup(this.updateCorrelationId);
      this.$hub.$off('feedback-process-updated', this.onProcessUpdated);
    }
  },

  components: {
    createFeedbackModal,
    feedbackListItem
  }
};
</script>
