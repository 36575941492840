<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="item.loading"
      ></v-progress-circular>

      <v-icon
        v-else-if="item.loading !== undefined && !item.loading"
        color="success"
      >
        mdi-text-box-check-outline
      </v-icon>

      <v-icon v-else>mdi-text-box-outline</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{
        $t(`feedbacks.processTypes.${item.processType}`)
      }}</v-list-item-title>

      <v-list-item-subtitle class="mt-1">
        <v-icon small class="mr-1">mdi-account</v-icon>
        {{ item.donorName }}
      </v-list-item-subtitle>

      <v-list-item-subtitle class="mt-1" v-if="isAdmin">
        <v-select
          v-model="formId"
          :items="formOptions"
          item-value="value"
          item-text="label"
          dense
          small
          label
          prepend-inner-icon="mdi-clipboard-outline"
          @change="onChangeForm"
        >
        </v-select>
        <v-dialog
          v-model="confirmationVisible"
          persistent
          transition="dialog-top-transition"
          max-width="600"
        >
          <v-card>
            <v-toolbar color="secondary" dark>
              {{ $t('feedbacks.setForm') }}
            </v-toolbar>
            <v-card-text>
              <v-skeleton-loader
                v-if="isLoading"
                type="article, list-item, actions"
                :loading="true"
                class="mt-4"
              ></v-skeleton-loader>
              <div v-else class="pt-4">
                {{ $t('feedbacks.setFormWarning') }}
                <br /><br />
                {{ $t('feedbacks.setFormConfirmation') }}
              </div>
            </v-card-text>
            <v-card-actions v-if="!isLoading">
              <v-spacer></v-spacer>
              <v-btn text color="secondary" @click="resetChangeForm">
                {{ $t('no') }}
              </v-btn>
              <v-btn text color="secondary" @click="changeForm(true)">
                {{ $t('yes') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item-subtitle>

      <v-list-item-subtitle class="mt-1">
        <v-chip
          v-if="!isAdmin"
          small
          dense
          :color="getStatusColor(item.statusId, item.processType)"
        >
          {{ localizeStatus(item.processType, item.statusId) }}
        </v-chip>
        <v-select
          v-else
          v-model="item.statusId"
          :items="statusOptions"
          item-value="value"
          item-text="label"
          dense
          small
          @change="changeStatus"
        >
          <template #selection>
            <v-chip :color="getStatusColor(item.statusId, item.processType)">
              {{ localizeStatus(item.processType, item.statusId) }}
            </v-chip>
          </template>
        </v-select>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="item.loading === undefined">
      <process-dialog :process="item">
        <template v-slot:header>
          {{ $t(`feedbacks.processTypes.${item.processType}`) }}
          {{ $t('for') }}
          {{ recipient.firstName }}
          {{ recipient.lastName }}
        </template>
      </process-dialog>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import processDialog from '@/components/dialogs/process.vue';
import processMixins from '@/mixins/feedback-process.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      require: true
    },

    recipient: {
      type: Object,
      required: true
    },

    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mixins: [processMixins],

  data() {
    return {
      formId: null,
      confirmationVisible: false
    };
  },

  computed: {
    ...mapGetters({
      workflowSettings: 'workflowSettings',
      forms: 'forms/getForms'
    }),

    isLoading() {
      return this.item.loading;
    },

    statusOptions() {
      const settings = this.workflowSettings.find(
        (x) => x.processType == this.item.processType
      );
      const options = settings.statusList.map((x) => ({
        value: parseInt(x.statusId),
        label: this.localizeStatus(settings.processType, x.statusId)
      }));
      options.sort((x, y) => x.value - y.value);
      return options;
    },

    formOptions() {
      const forms = this.forms.filter((x) => x.type == this.item.processType);

      const options = forms.map((x) => ({
        value: x.id,
        label: this.localize(x.name)
      }));
      options.sort((x, y) => (x.label < y.label ? -1 : 1));
      return options;
    }
  },

  async mounted() {
    this.formId = this.item.feedbacks[0].formId;
    await this.loadForms();
  },

  methods: {
    ...mapActions({
      setProcessStatus: 'feedbacks/setProcessStatus',
      setProcessForm: 'feedbacks/setProcessForm',
      loadForms: 'forms/listForms'
    }),

    async changeStatus() {
      await this.setProcessStatus(this.item);
      this.$emit('updated');
    },

    async onChangeForm() {
      const inProgress = this.item.feedbacks.some(
        (x) => x.inProgress || x.submitted || x.approved
      );
      if (inProgress) {
        // show modal
        this.confirmationVisible = true;
      } else {
        await this.changeForm(false);
      }
    },

    async changeForm(resetProgress) {
      this.item.feedbacks.forEach((x) => (x.formId = this.formId));
      await this.setProcessForm(this.item);
      if (resetProgress) {
        const settings = this.workflowSettings.find(
          (x) => x.processType == this.item.processType
        );
        this.item.statusId = settings.initialStatus;
        await this.changeStatus();
      }
      this.confirmationVisible = false;
    },

    resetChangeForm() {
      this.formId = this.item.feedbacks[0].formId;
      this.confirmationVisible = false;
    }
  },

  components: {
    processDialog
  }
};
</script>
